import React, { useEffect, useState } from 'react';
import { FirstAdvertise, SecondAdvertise } from '@/components/public/CustomAdvertise';
import { PAGE_CENTER_NEW, PAGE_CENTER_WA } from '@/utils';
import styles from './index.module.less';
import { getAdvertising } from '@/services/advertise';
import { transitionAdvertiseData } from '@/utils/advertise';

interface SwiperProps {
    elementId: string | number;
    styleType: number | string;
    canClose: boolean;
    /** 广告id */
    advertisingId: any[];
    interval?: number;
    customStyle: any;
    adCode: string;
    adList: any[];
    showRightData?: boolean;
    serviceData?: {
        isRight?: boolean;
        isFixedId?: string;
    };
}

const Advertise: React.FC<SwiperProps> = (props: SwiperProps) => {
    const { elementId, interval = 5, advertisingId, showRightData, serviceData } = props;

    const [advertisingData, setAdvertisingData] = useState<any>();

    useEffect(() => {
        if (advertisingId?.[1]) {
            getAdvertising({ ids: advertisingId?.[1] }).then((res: any) => {
                const { state, data } = res;

                if (state === 1) {
                    const result = transitionAdvertiseData(data[0]);
                    setAdvertisingData(result);
                }
            });
        }
    }, []);

    const { styleType, customStyle, adCode, canClose, adList = [] } = advertisingData || {};

    const { width, widthUnit, height, heightUnit, ...restStyle } = customStyle || {};

    return (
        <div className={styles.swiperContainBox}>
            {styleType === '1' && (
                <FirstAdvertise
                    elementId={elementId}
                    adCode={adCode}
                    adStyle={restStyle}
                    moreStyle={{ width: showRightData ? PAGE_CENTER_WA : PAGE_CENTER_NEW }}
                    serviceData={serviceData}
                />
            )}
            {styleType === '2' && (
                <SecondAdvertise
                    elementId={elementId}
                    interval={interval}
                    canClose={canClose}
                    customStyle={customStyle}
                    swiperList={adList}
                    serviceData={serviceData}
                />
            )}
        </div>
    );
};

export default Advertise;
